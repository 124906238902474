<template>
	<div class="tableCon">
		<div class="tableConTable">
			<div class="tableConTop">
				<el-row>
					<el-col :span="5" class="tableConTopLeft">
						<h3>
							<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('ca5a01a5adf20fe7')}}</el-button>
			
							<span class="tct_tit">{{$t('i18nn_7bfefc21c6aba7c6')}}</span>
			
							<!-- <el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
								<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
							</el-tooltip> -->
						</h3>
					</el-col>
					<el-col :span="19" class="tableConTopRig">
						
						<!-- <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button> -->
					</el-col>
				</el-row>
			</div>
		</div>
		<el-card style="">
			<div style="">
				<span>FBA<span>{{$t('5acbec83efb27445')}}</span></span>
				<el-input size="" clearable v-model="fbaAddrCode" style="width:500px;" @keyup.enter.native="searchFbaAddress()">
					<el-button slot="append" type="primary" icon="el-icon-search" @click="searchFbaAddress()"></el-button>
				</el-input>
			</div>
			<div v-loading="loading">
				<el-descriptions title="FBA">
					<el-descriptions-item label="名称1">{{FBAform.firstname}}</el-descriptions-item>
					<el-descriptions-item label="名称2">{{FBAform.lastname}}</el-descriptions-item>
					<el-descriptions-item :label="$t('c3755bab459a2cbc')">{{FBAform.company}}</el-descriptions-item>
					<el-descriptions-item label="地址1">{{FBAform.addr1}}</el-descriptions-item>
					<el-descriptions-item label="地址2">{{FBAform.addr2}}</el-descriptions-item>
					<el-descriptions-item :label="$t('e05b5d049b64b040')">{{FBAform.city}}</el-descriptions-item>
					<el-descriptions-item :label="$t('f04d208f84e6c343')">{{FBAform.province}}</el-descriptions-item>
					<el-descriptions-item :label="$t('b166e4e8fe9513fa')">{{FBAform.country}}</el-descriptions-item>
					<el-descriptions-item :label="$t('c4913ab43009b365')">{{FBAform.postcode}}</el-descriptions-item>
					<el-descriptions-item :label="$t('e82c9fff83ec2d91')">{{FBAform.mobile}}</el-descriptions-item>
					<el-descriptions-item label="Email">{{FBAform.mail}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_9461d15f7ba8ad99')">
						{{FBAform.addr1}},{{FBAform.addr2}},{{FBAform.city}},{{FBAform.province}},{{FBAform.country}},{{FBAform.postcode}}
					</el-descriptions-item>
				</el-descriptions>
			</div>
			<div>
				<span>HYTX<span>{{$t('c944a6686d996ab3')}}</span></span>
				<whNoAllSelect :size="''" ref="whNoAllSelect" @changeData="changWhNo" @loadAllSuccess="whLoadSuccess" :width="500">
				</whNoAllSelect>
			</div>
			<div>
				<el-descriptions :title="$t('4c6841ce9043c9fe')">
					<el-descriptions-item label="名称1">{{Whform.firstname}}</el-descriptions-item>
					<el-descriptions-item label="名称2">{{Whform.lastname}}</el-descriptions-item>
					<el-descriptions-item :label="$t('c3755bab459a2cbc')">{{Whform.company}}</el-descriptions-item>
					<el-descriptions-item label="地址1">{{Whform.addr1}}</el-descriptions-item>
					<el-descriptions-item label="地址2">{{Whform.addr2}}</el-descriptions-item>
					<el-descriptions-item :label="$t('e05b5d049b64b040')">{{Whform.city}}</el-descriptions-item>
					<el-descriptions-item :label="$t('f04d208f84e6c343')">{{Whform.province}}</el-descriptions-item>
					<el-descriptions-item :label="$t('b166e4e8fe9513fa')">{{Whform.country}}</el-descriptions-item>
					<el-descriptions-item :label="$t('c4913ab43009b365')">{{Whform.postcode}}</el-descriptions-item>
					<el-descriptions-item :label="$t('e82c9fff83ec2d91')">{{Whform.mobile}}</el-descriptions-item>
					<el-descriptions-item label="Email">{{Whform.mail}}</el-descriptions-item>
					<el-descriptions-item :label="$t('i18nn_9461d15f7ba8ad99')">
						{{Whform.addr1}},{{Whform.addr2}},{{Whform.city}},{{Whform.province}},{{Whform.country}},{{Whform.postcode}}
					</el-descriptions-item>
				</el-descriptions>
			</div>
		</el-card>

	</div>
</template>

<script>
	import whNoAllSelect from '@/components/WarehouseCenter2/components/whNoAllSelect.vue';

	export default {
		components: {
			whNoAllSelect
		},
		data() {
			return {
				loading: false,
				fbaAddrCode: "", //FBA仓库地址编号
				FBAform: {
					firstname: '',
					lastname: '',
					company: '',
					addr1: '',
					addr2: '',
					city: '',
					province: '',
					postcode: '',
					country: 'US',
					mobile: '',
					mail: '',
					addr_type: ''
				},
				whNoList: [],
				Whform: {
					firstname: '',
					lastname: '',
					company: '',
					addr1: '',
					addr2: '',
					city: '',
					province: '',
					postcode: '',
					country: 'US',
					mobile: '',
					mail: '',
					addr_type: ''
				}
			};
		},
		activated() {

		},
		mounted() {

		},
		methods: {
			goBack(){
				this.$router.go(-1);
			},
			whLoadSuccess(list) {
				this.whNoList = list;
			},

			changWhNo(data) {
				console.log('changWhNo', data);
				// this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				// this.initData();
				let findWh = this.whNoList.find(item => {
					return item.whNo == data.code;
				});
				if (!!findWh) {
					// this.form.receiverVo.country = findWh.country;
					this.Whform.province = findWh.state;
					this.Whform.city = findWh.city;
					this.Whform.addr1 = findWh.address;
					this.Whform.addr2 = "";
					this.Whform.postcode = findWh.postalCode;
					this.Whform.mobile = findWh.phoneNumber;
					this.Whform.mail = findWh.email;
					this.Whform.company = findWh.company;
					this.Whform.firstname = findWh.name;
					this.Whform.lastname = "";
				}
				// company: '',
				// addr1: '',
				// addr2: '',
				// city: '',
				// province: '',
				// postcode: '',
				// country: '',
				// mobile: '',
				// mail: '',

			},

			//查询FBA地址
			searchFbaAddress() {
				console.log('searchFbaAddress');
				let fbaAddrCode = "";
				// if(1==type){
				fbaAddrCode = this.fbaAddrCode;
				// } else if(2==type){
				// 	fbaAddrCode = this.fbaAddrCode2;
				// }
				if (!fbaAddrCode) {
					this.$message.warning(this.$t('5a9aefbc03c778f7') + 'FBA' + this.$t('i18nn_a68426dce989b497'));
					return;
				}
				this.FBAform.province = "";
				this.FBAform.city = "";
				this.FBAform.addr1 = "";
				this.FBAform.addr2 = "";
				this.FBAform.postcode = "";
				this.FBAform.mobile = "";
				this.FBAform.mail = "";
				this.FBAform.company = "";
				this.FBAform.firstname = "";
				this.FBAform.lastname = "";
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhTransferQueryFba + '?fbaCode=' + fbaAddrCode, {})
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_776496ec446f21f9'));
						console.log(data);
						this.loading = false;
						if (200 == data.code && data.data) {
							// this.form.country = addrData.country;
							// this.form.state = addrData.state;
							// this.form.city = addrData.city;
							// this.form.postalCode = addrData.zipCode;
							// this.form.addr = addrData.address;
							// this.form.phone = addrData.phone;
							let findWh = data.data;
							// if(1==type){
							this.FBAform.province = findWh.state;
							this.FBAform.city = findWh.city;
							this.FBAform.addr1 = findWh.address;
							this.FBAform.addr2 = "";
							this.FBAform.postcode = findWh.zipCode;
							this.FBAform.mobile = findWh.phone;
							this.FBAform.mail = "";
							this.FBAform.company = "";
							this.FBAform.firstname = findWh.name;
							this.FBAform.lastname = "";
							// } else if(2==type){
							// 	this.form.senderVo.province = findWh.state;
							// 	this.form.senderVo.city = findWh.city;
							// 	this.form.senderVo.addr1 = findWh.address;
							// 	this.form.senderVo.addr2 = "";
							// 	this.form.senderVo.postcode = findWh.zipCode;
							// 	this.form.senderVo.mobile = findWh.phone;
							// 	this.form.senderVo.mail = "";
							// 	this.form.senderVo.company = "";
							// 	this.form.senderVo.firstname = findWh.name;
							// 	this.form.senderVo.lastname = "";
							// }

						} else {
							// if (!data.msg) {
							// 	data.msg = this.$t('i18nn_d74d0bd89431d6d5');
							// }
							// this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('tips.errorData'));
						this.loading = false;
						// this.$message.error('查询失败!');
					});
			},
		}
	};
</script>

<style scoped lang="less">


</style>